<template>
  <div class="base-form">
    <page-form
      ref="baseFormRef"
      v-bind="configOptions"
      @handleNewClick="handleSubmit"
      :pageInfo="pageInfo"
    >
    </page-form>
    <page-content
      v-if="pageType === 'view'"
      style="margin-top: 10px"
      ref="pageContentRef"
      v-bind="tableOptionsConfig"
      @newBtnClick="handleNew"
      @pageInfoChange="pageInfoChangeAction"
    >
      <template #otherHandler="scope">
        <el-button
          v-if="isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-s-promotion"
          type="warning"
          size="mini"
          plain
          @click="handleReleaseClick(scope.row)"
          >{{ $t('general.release') }}</el-button
        >
        <el-button
          v-if="isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-lock"
          size="mini"
          plain
          type="warning"
          @click="handleEnableClick(scope.row, 'disable')"
          >{{ $t('general.disable') }}</el-button
        >
        <el-button
          v-if="!isNormalStatus(scope.row.status)"
          class="opt-btn"
          icon="el-icon-unlock"
          size="mini"
          plain
          @click="handleEnableClick(scope.row, 'enable')"
          >{{ $t('general.enable') }}</el-button
        >
      </template>
    </page-content>
  </div>
</template>
<script setup>
import pageForm from '@/components/PageForm/page-form.vue'
import pageContent from '@/components/PageContent/page-content.vue'

import {
  baseNewFormConfig,
  baseUpdateFormConfig,
  baseViewFormConfig
} from './config/index'
import { contentTableConfig } from './config/table.js'

import { ref, getCurrentInstance, computed } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import i18n from '@/i18n'
import { cloneDeep } from 'lodash'
import { ElMessageBox, ElMessage } from 'element-plus'
// import { usePermission } from '@/hooks/use-permission'
// import { findCurrentSelectedTreeNode } from '@/utils/util'

const routes = useRoute()
const router = useRouter()
const { t } = i18n.global

const pageContentRef = ref('')
const id = routes.params.id
const pageInitialParams = {
  id: id
}

// 表格基础配置项
const tableOptions = {
  moduleName: 'file', // 所属的模块
  pageName: 'otaFile', // 所属的页面
  contentTableConfig, // 表格列的基础配置
  isShowCreateSlot: true,
  isShowViewBtn: false,
  isShowEditBtn: false,
  pageInitialQuery: pageInitialParams,
  pageAction: 'file/getOtaFileLists', // 获取表格的Action
  deleteRecordAction: 'file/deleteOtaFileById', // 删除record的Action
  pageCountGetter: 'file/pageListCount', // 获取表格记录数据总和getters
  pageListGetter: 'file/pageListData' // 获取表格列表数据getters
}
const tableOptionsConfig = computed(() => tableOptions)

// 1、预定义当前页面的map映射数组
const pageMapArray = [
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'OtaView',
    baseFormConfig: baseViewFormConfig,
    pageTitle: t('file.view-ota-file'),
    pageType: 'view'
  },
  {
    // view routesName 跟你配置路由的name相关
    routesName: 'OtaEdit',
    baseFormConfig: baseUpdateFormConfig,
    pageTitle: t('file.edit-ota-file'),
    pageType: 'edit',
    isResetFields: false
  },
  {
    routesName: 'OtaNew',
    baseFormConfig: baseNewFormConfig,
    pageTitle: t('file.add-ota-file'),
    pageType: 'add'
  }
]
// 2、根据当前路由对象中的name属性判断当前页面
const currentPageConfig = pageMapArray.find((item) => {
  if (item.routesName === routes.name) return item
})

const store = useStore()

// 3、将需要用到的属性解构出来
const { baseFormConfig, pageType } = currentPageConfig

const baseFormConfigRef = computed(() => {
  return baseFormConfig
})

// 4、区分当前页面是否需要转化options选项
const configOptions = ref({})

currentPageConfig.baseFormConfig = baseFormConfigRef
configOptions.value = currentPageConfig

const { appContext } = getCurrentInstance()
const pageInfo = ref({})
const handleMessageCommit =
  appContext.config.globalProperties.$handleMessageCommit

// 获取页面操作的权限
// const isEnable = usePermission(
//   tableOptions.moduleName,
//   tableOptions.pageName,
//   'switch'
// )

// const isRelease = usePermission(
//   tableOptions.moduleName,
//   tableOptions.pageName,
//   'release'
// )

const isNormalStatus = (status) => {
  return status === '00'
}

// 5、定义方法获取当前页面的初始化数据
const getCurrentPageInfo = async () => {
  const otaInfo = await store.dispatch('file/getOtaById', { id })
  if (JSON.stringify(otaInfo) !== '{}') {
    otaInfo.tenantWhitelist = otaInfo.tenantWhitelist?.split(',') || []
    otaInfo.tenantId = store.getters.tenantDataMap[otaInfo?.tenantId]
    pageInfo.value = otaInfo
  }
}

// 6、判断当前页是否需要调用getCurrentPageInfo
if (['view', 'edit'].includes(pageType)) {
  getCurrentPageInfo()
} else {
  pageInfo.value = {}
}

const handleNew = () => {
  router.push('/file/ota/import')
}

const handleEnableClick = async (row, type) => {
  const url =
    type === 'enable'
      ? 'handleEnableOtaFileAction'
      : 'handleDisableOtaFileAction'
  handleMessageCommit(`file/${url}`, row.id).then(() => {
    pageContentRef.value.getPageData({ id })
  })
}

const handleReleaseClick = async (row) => {
  ElMessageBox.confirm(
    'Are you sure to release the OTA file?',
    t('popup.confirm-warning-title'),
    {
      confirmButtonText: t('popup.confirm-ok-btn'),
      cancelButtonText: t('popup.confirm-cancel-btn'),
      type: 'warning'
    }
  )
    .then(async () => {
      await handleMessageCommit('file/handleReleaseOtaAction', row.id).then(
        () => {
          pageContentRef.value.getPageData({ id })
        }
      )
    })
    .catch((action) => {
      if (action === 'cancel') {
        ElMessage({
          type: 'info',
          message: t('popup.cancel-tip')
        })
      }
    })
}

const pageInfoChangeAction = () => {
  pageContentRef.value.getPageData({ id })
}

// 7、定义被提交的方法
const handleSubmit = async (data) => {
  // 处理iconUrl
  const form = cloneDeep(data)
  let url = 'handleOtaAction'

  // form.iconUrl = store.state.system.imgPathList[routes.path] || ''
  // 处理tenantList
  form.tenantWhitelist =
    data.tenantWhitelist && Array.isArray(data.tenantWhitelist)
      ? data.tenantWhitelist?.join(',')
      : ''
  if (pageType === 'edit') {
    form.id = id
    url = 'handleUpdateOta'
  }
  handleMessageCommit(`file/${url}`, form).then(() => {
    router.push('/file/ota')
  })
}
</script>

<style scoped lang="scss">
.base-form {
  .form-item {
    padding: 5px 20px;
    .file-wrap {
      display: flex;
      align-items: center;
      &:hover {
        cursor: pointer;
        color: #64b5fc;
      }
      .download-icon {
        font-size: 16px;
        margin-left: 5px;
      }
    }
  }
}
</style>
