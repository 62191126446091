import { getCommonParamsItemLabel } from '@/utils/common'

export const contentTableConfig = {
  // title: '('common.app-application-list')',
  title: '',
  propList: [
    {
      prop: 'versionCode',
      label: 'file.version-code'
    },
    // {
    //   prop: 'versionName',
    //   label: 'file.version-name'
    // },
    {
      prop: 'downloadCount',
      label: 'file.download-counts'
    },
    {
      prop: 'status',
      label: 'general.status',
      width: 150,
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'ota_file_status')
      }
    },
    {
      prop: 'otaEnvType',
      label: 'file.ota-env',
      formatter: (row) => {
        return getCommonParamsItemLabel(row, 'ota_env_type')
      }
    },
    {
      prop: 'description',
      label: 'general.description',
      width: 200
    },
    {
      prop: 'createdTime',
      slotName: 'formatZeroTime',
      label: 'common.app-creation-time',
      width: 180
    },
    {
      prop: 'options',
      label: 'common.app-operation',
      width: 200,
      slotName: 'handler'
    }
  ],
  showIndexColumn: true,
  showColSetting: true,
  tableColCheckedListName: 'otaFileTableCheckedCol',
  tableColAllListName: 'otaFileTableAllCol'
}
